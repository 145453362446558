@font-face {
  font-family: "GreatVibes";
  src: url("../fonts/great-vibes/GreatVibes-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ZCoolXiaoWei";
  src: url("../fonts/zcoolxiaowei/ZCOOLXiaoWei-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "extraLight";
  src: url("../fonts/extra-light/Kepler Std Medium.otf") format("truetype");
}

.custom-card {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.card-img {
  width: 100%;
  height: auto;
}

.card-overlay {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 85%;
  height: 90%;
  font-family: "extraLight";
  color: white;
  font-size: 100%;
}

.card-overlay h1 {
  position: absolute;
  bottom: 260px;
  font-family: "GreatVibes";
  font-size: 90px;
  color: #ffefb1;
  text-shadow: 0 0 10px #e7a44a, 0 0 20px #e7a44a, 0 0 30px #e7a44a;
}

.card-event-datetime {
  position: absolute;
  bottom: 190px;
  margin-bottom: 20px;
}

.card-event-datetime p {
  font-family: "ZCoolXiaoWei";
  font-weight: 100;
  color: rgb(243, 242, 242);
  font-size: 17px;
}

.card-event-venue {
  position: absolute;
  bottom: 135px;
  margin: 0 auto;
  width: 70%;
  font-family: "ZCoolXiaoWei";
  font-weight: 100;
  color: #ffde59;
  font-size: 21px;
}

#cardbase {
  position: absolute;
  bottom: 15px;
  width: 85px;
  height: 102px;
  display: flex;
  flex-direction: column;
  background-color: #930700;
  border-radius: 20px; 
  box-shadow: 0 0 10px #e7a44a, 0 0 20px #e7a44a;
}

.card4 {
  display: flex;
  align-items: center;
  padding: 3px;
}

.boxCard4 {
  background-color: #930700;
}

.card4img {
  width: 100%;
  padding: 9px;
  border-radius: 20px; 
}

.card4 h2 {
  font-family: 'Roboto', sans-serif;
  font-weight: 900;
  font-size: 9px;
  margin-top: 0px;
  text-transform: uppercase;
  color: #fff;
}

@media only screen and (max-width: 290px) {
  .card-overlay {
    font-size: 50%;
  }

  .card-overlay h1 {
    top: 67px;
    font-size: 47px;
  }
  
  .card-event-datetime {
    bottom: 95px;
  }
  
  .card-event-datetime p {
    font-size: 9px;
  }
  
  .card-event-venue {
    bottom: 73px;
    font-size: 11px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 5px;
    width: 47px;
    height: 55px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 3px;
  }
  
  .card4 h2 {
    font-size: 5px;
  }
}

@media (min-width: 291px) and (max-width: 330px) {
  .card-overlay {
    font-size: 60%;
  }

  .card-overlay h1 {
    top: 73px;
    font-size: 53px;
  }
  
  .card-event-datetime {
    bottom: 97px;
  }
  
  .card-event-datetime p {
    font-size: 10px;
  }
  
  .card-event-venue {
    bottom: 73px;
    font-size: 12px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 5px;
    width: 47px;
    height: 55px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 3px;
  }
  
  .card4 h2 {
    font-size: 5px;
  }
}

@media (min-width: 331px) and (max-width: 370px) {
  .card-overlay {
    font-size: 70%;
  }

  .card-overlay h1 {
    top: 85px;
    font-size: 60px;
  }
  
  .card-event-datetime {
    bottom: 125px;
  }
  
  .card-event-datetime p {
    font-size: 12px;
  }
  
  .card-event-venue {
    bottom: 90px;
    font-size: 15px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 7px;
    width: 52px;
    height: 65px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 4px;
  }
  
  .card4 h2 {
    font-size: 7px;
  }
}

@media (min-width: 371px) and (max-width: 410px) {
  .card-overlay {
    font-size: 75%;
  }

  .card-overlay h1 {
    top: 92px;
    font-size: 65px;
  }
  
  .card-event-datetime {
    bottom: 135px;
  }
  
  .card-event-datetime p {
    font-size: 14px;
  }
  
  .card-event-venue {
    bottom: 100px;
    font-size: 16px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 5px;
    width: 67px;
    height: 80px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 6px;
  }
  
  .card4 h2 {
    font-size: 8px;
  }
}

@media (min-width: 411px) and (max-width: 445px) {
  .card-overlay {
    font-size: 80%;
  }

  .card-overlay h1 {
    top: 100px;
    font-size: 70px;
  }
  
  .card-event-datetime {
    bottom: 170px;
  }
  
  .card-event-datetime p {
    font-size: 16px;
  }
  
  .card-event-venue {
    bottom: 123px;
    font-size: 18px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 5px;
    width: 75px;
    height: 95px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 6px;
  }
  
  .card4 h2 {
    font-size: 10px;
  }
}

@media (min-width: 446px) and (max-width: 504px) {
  .card-overlay {
    font-size: 85%;
  }

  .card-overlay h1 {
    top: 117px;
    font-size: 80px;
  }
  
  .card-event-datetime {
    bottom: 170px;
  }
  
  .card-event-datetime p {
    font-size: 16px;
  }
  
  .card-event-venue {
    bottom: 123px;
    font-size: 18px;
  }

  #cardbase {
    border-radius: 10px; 
    bottom: 5px;
    width: 75px;
    height: 95px;
  }
  
  .card4 {
    padding: 2px;
  }
  
  .card4img {
    border-radius: 10px; 
    padding: 6px;
  }
  
  .card4 h2 {
    font-size: 10px;
  }
}