.results-list {
    width: 100%;
    background-color: white;
    display: flex;
    position: absolute;
    top: 90%;
    left: 0;
    flex-direction: column;
    box-shadow: 0px 0px 8px #ddd;
    border-radius: 10px;
    margin-top: 0px;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
  }
  
  .results-list-2 {
    top: 120% !important;
  }

  .search-result {
    padding: 10px 20px;
  }
  
  .search-result:hover {
    background-color: #efefef;
  }